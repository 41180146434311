import React from "react";
import { Button } from "@mui/material";
import clsx from "clsx";
import styles from "./buttonSpace.module.scss";

const ButtonSpace = ({ editMember, isEdit, isSavingPost, saveMember, noStyle=true }) => {
  return (
    <div className={clsx({[styles.buttonContainer]: noStyle})}>
      {isEdit ? (
        <Button
          variant="contained"
          color="primary"
          onClick={editMember}
          disabled={isSavingPost}
        >
          Editar
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={saveMember}
          disabled={isSavingPost}
        >
          Guardar
        </Button>
      )}
    </div>
  );
};

export default ButtonSpace;
