export const FILTER_OPTIONS = {
    general: "general",
    schoolCode: "schoolCode"
  }

export const SERVICE_USED_BY = {
  aspiring: "aspiring",
  PERSON: "person",
  member: "member",
  company: "company"
}